<!--
  - Copyright (C) 2024. Archimedes Exhibitions GmbH,
  - Saarbrücker Str. 24, Berlin, Germany
  -
  - This file contains proprietary source code and confidential
  - information. Its contents may not be disclosed or distributed to
  - third parties unless prior specific permission by Archimedes
  - Exhibitions GmbH, Berlin, Germany is obtained in writing. This applies
  - to copies made in any form and using any medium. It applies to
  - partial as well as complete copies.
  -->

<template>
  <div class="vertical-center">
    <b-container>
      <div class="row">
        <div class="col-md-6 align-self-center">
          <h1>403</h1>
          <h2>NO ACCESS!</h2>
          <p>
            You are not allowed to view this page.<br />
            You don't have access rights for the following scopes:
          </p>
          <div
            class="text-secondary"
            v-for="scope in missingScopes"
            :key="scope"
          >
            {{ scope }}
          </div>
          <b-button class="mt-4" variant="success" to="/">HOME</b-button>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
  import Vue from 'vue'
  export default {
    name: 'NotFound',
    computed: {
      missingScopes () {
        return Vue.prototype.$keycloakmanager.getMissingScopes(
          this.$route.query.scope, this.$route.query.methods.split(',')
        )
      }
    }
  }
</script>

<style scoped lang="scss">
  .vertical-center {
    min-height: 100%; /* Fallback for browsers do NOT support vh unit */
    min-height: 85vh; /* These two lines are counted as one :-)       */

    display: flex;
    align-items: center;
  }
</style>
